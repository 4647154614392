import React from 'react'
import './whatsapp.css';

export const WhatsappButtonScreen = () => {
    return (
        <>
            <a className='btn-wsp' href="https://walink.co/f3a001" target="_blank" style={{textDecoration: 'none' }}>
                <i className="fab fa-whatsapp fa-1x"  ></i>

            </a>
        </>
    )
}
